"use client";

// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { defaultLang } from "./config-lang";
import { translationEn } from "./langs/en";
import { translationFr } from "./langs/fr";

// ----------------------------------------------------------------------

const lng = defaultLang.value;

type ObjectPath<T extends object, D extends string = ""> = {
  [K in keyof T]: `${D}${Exclude<K, symbol>}${"" | (T[K] extends object & { length?: never } ? ObjectPath<T[K], "."> : "")}`;
}[keyof T];
export type ILang = ObjectPath<typeof translationFr>;

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      fr: { translations: translationFr },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

type IResourceBundle = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  en: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fr: any;
};
export function addTranslations(currentTranslations: IResourceBundle) {
  for (const [key, value] of Object.entries(currentTranslations)) {
    i18n.addResourceBundle(key, "translations", value);
  }
}
