import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import type { MotionProps } from "framer-motion";
import { m } from "framer-motion";
import React from "react";
import { useResponsive } from "../../hooks/use-responsive";
import { varContainer } from "./variants";

// ----------------------------------------------------------------------

type IProps = BoxProps & MotionProps;
interface Props extends IProps {
  children: React.ReactNode;
  disableAnimatedMobile?: boolean;
}
export default function MotionViewport({
  children,
  disableAnimatedMobile = true,
  ...other
}: Props) {
  const smDown = useResponsive("down", "sm");
  if (smDown && disableAnimatedMobile) {
    return <Box {...other}>{children}</Box>;
  }
  return <Box component={m.div} initial="initial" whileInView="animate" viewport={{
    once: true,
    amount: 0.3
  }} variants={varContainer()} {...other} data-sentry-element="Box" data-sentry-component="MotionViewport" data-sentry-source-file="motion-viewport.tsx">
      {children}
    </Box>;
}