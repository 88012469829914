import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import type { MotionProps } from "framer-motion";
import { m } from "framer-motion";
import React from "react";
import { varContainer } from "./variants";

// ----------------------------------------------------------------------

type IProps = BoxProps & MotionProps;
export interface Props extends IProps {
  animate?: boolean;
  action?: boolean;
}
export default function MotionContainer({
  animate,
  action = false,
  children,
  ...other
}: Props) {
  if (action) {
    return <Box component={m.div} initial={false} animate={animate ? "animate" : "exit"} variants={varContainer()} {...other}>
        {children}
      </Box>;
  }
  return <Box component={m.div} initial="initial" animate="animate" exit="exit" variants={varContainer()} {...other} data-sentry-element="Box" data-sentry-component="MotionContainer" data-sentry-source-file="motion-container.tsx">
      {children}
    </Box>;
}