import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
// import { useTheme } from "@mui/material/styles";
import React, { forwardRef } from "react";
import { RouterLink } from "../../routes/components";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

// eslint-disable-next-line react/display-name
const Logo = forwardRef<HTMLDivElement, LogoProps>(({
  disabledLink = false,
  sx,
  ...other
}, ref) => {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = <Box ref={ref} component="div" sx={{
    width: 40,
    height: 40,
    display: "inline-flex",
    ...sx
  }} {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 379 379" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="1.5">
        <g transform="matrix(1,0,0,1,-223.701,-79.381)">
          <g>
            <g transform="matrix(1.0102,0,0,1.125,-446.239,-516.221)">
              <path d="M1037.69,579.682L1037.69,815.465C1037.69,843.203 1012.61,865.723 981.72,865.723L719.142,865.723C688.252,865.723 663.174,843.203 663.174,815.465L663.174,579.682C663.174,551.944 688.252,529.424 719.142,529.424L981.72,529.424C1012.61,529.424 1037.69,551.944 1037.69,579.682Z" fill="url(#_Radial1)" />
            </g>
            <g transform="matrix(1.0102,0,0,1.125,-446.239,-516.221)">
              <path d="M1037.69,579.682L1037.69,815.465C1037.69,843.203 1012.61,865.723 981.72,865.723L719.142,865.723C688.252,865.723 663.174,843.203 663.174,815.465L663.174,579.682C663.174,551.944 688.252,529.424 719.142,529.424L981.72,529.424C1012.61,529.424 1037.69,551.944 1037.69,579.682Z" fill="url(#_Radial2)" />
            </g>
            <g transform="matrix(0.8,0,0,0.60485,-257.866,-409.781)">
              <path d="M785.532,994.152L785.532,1200.29C785.532,1213.6 777.362,1224.4 767.3,1224.4L758.924,1224.4C748.862,1224.4 740.692,1213.6 740.692,1200.29L740.692,994.152C740.692,980.843 748.862,970.037 758.924,970.037L767.3,970.037C777.362,970.037 785.532,980.843 785.532,994.152Z" fill="rgb(130,21,30)" />
            </g>
            <g transform="matrix(1.17761,0.129004,-0.129261,1.17996,-349.312,-1077.14)">
              <clipPath id="_clip3">
                <rect x="706.418" y="1102.22" width="43.679" height="27.671" />
              </clipPath>
              <g clipPath="url(#_clip3)">
                <path d="M764.074,1039.75C795.895,1039.75 821.73,1066.4 821.73,1099.23C821.73,1132.06 795.895,1158.71 764.074,1158.71C732.253,1158.71 706.418,1132.06 706.418,1099.23C706.418,1066.4 732.253,1039.75 764.074,1039.75ZM764.074,1066.25C781.72,1066.25 796.046,1081.03 796.046,1099.23C796.046,1117.44 781.72,1132.22 764.074,1132.22C746.428,1132.22 732.102,1117.44 732.102,1099.23C732.102,1081.03 746.428,1066.25 764.074,1066.25Z" fill="rgb(130,21,30)" />
              </g>
            </g>
            <g transform="matrix(-1.3215,0,0,1.25688,1459.35,-1063.29)">
              <clipPath id="_clip4">
                <rect x="706.418" y="1100.85" width="68.075" height="58.042" />
              </clipPath>
              <g clipPath="url(#_clip4)">
                <path d="M764.074,1039.75C795.895,1039.75 821.73,1066.4 821.73,1099.23C821.73,1132.06 795.895,1158.71 764.074,1158.71C732.253,1158.71 706.418,1132.06 706.418,1099.23C706.418,1066.4 732.253,1039.75 764.074,1039.75ZM764.074,1070.5C779.447,1070.5 791.928,1083.37 791.928,1099.23C791.928,1115.09 779.447,1127.97 764.074,1127.97C748.701,1127.97 736.22,1115.09 736.22,1099.23C736.22,1083.37 748.701,1070.5 764.074,1070.5Z" fill="rgb(130,21,30)" />
              </g>
            </g>
            <g transform="matrix(0.8,0,0,0.626703,-102.694,-430.98)">
              <path d="M785.532,993.311L785.532,1201.13C785.532,1213.97 777.362,1224.4 767.3,1224.4L758.924,1224.4C748.862,1224.4 740.692,1213.97 740.692,1201.13L740.692,993.311C740.692,980.466 748.862,970.037 758.924,970.037L767.3,970.037C777.362,970.037 785.532,980.466 785.532,993.311Z" fill="rgb(130,21,30)" />
            </g>
            <g transform="matrix(0.597714,0,0,0.464262,319.206,-149.535)">
              <path d="M317.782,656.187L384.362,756.736C385.245,758.071 385.446,759.945 384.875,761.537C384.304,763.129 383.067,764.148 381.705,764.148L248.544,764.148C247.182,764.148 245.944,763.129 245.374,761.537C244.803,759.945 245.003,758.071 245.887,756.736L312.467,656.187C313.13,655.185 314.102,654.608 315.124,654.608C316.147,654.608 317.118,655.185 317.782,656.187Z" fill="rgb(130,21,30)" />
            </g>
            <g transform="matrix(0.8,0,0,0.635777,-276.171,-449.169)">
              <path d="M785.532,992.979L785.532,1201.46C785.532,1214.12 777.362,1224.4 767.3,1224.4L758.924,1224.4C748.862,1224.4 740.692,1214.12 740.692,1201.46L740.692,992.979C740.692,980.317 748.862,970.037 758.924,970.037L767.3,970.037C777.362,970.037 785.532,980.317 785.532,992.979Z" fill="white" />
            </g>
            <g transform="matrix(1.20309,0,0,1.29996,-533.474,-1114.45)">
              <clipPath id="_clip5">
                <rect x="706.418" y="1099.23" width="59.489" height="59.482" />
              </clipPath>
              <g clipPath="url(#_clip5)">
                <path d="M764.074,1039.75C795.895,1039.75 821.73,1066.4 821.73,1099.23C821.73,1132.06 795.895,1158.71 764.074,1158.71C732.253,1158.71 706.418,1132.06 706.418,1099.23C706.418,1066.4 732.253,1039.75 764.074,1039.75ZM764.074,1070.5C779.447,1070.5 791.928,1083.37 791.928,1099.23C791.928,1115.09 779.447,1127.97 764.074,1127.97C748.701,1127.97 736.22,1115.09 736.22,1099.23C736.22,1083.37 748.701,1070.5 764.074,1070.5Z" fill="white" />
              </g>
            </g>
            <g transform="matrix(-1.20309,0,0,1.29996,1357.29,-1114.45)">
              <clipPath id="_clip6">
                <rect x="706.418" y="1099.23" width="59.489" height="59.482" />
              </clipPath>
              <g clipPath="url(#_clip6)">
                <path d="M764.074,1039.75C795.895,1039.75 821.73,1066.4 821.73,1099.23C821.73,1132.06 795.895,1158.71 764.074,1158.71C732.253,1158.71 706.418,1132.06 706.418,1099.23C706.418,1066.4 732.253,1039.75 764.074,1039.75ZM764.074,1070.5C779.447,1070.5 791.928,1083.37 791.928,1099.23C791.928,1115.09 779.447,1127.97 764.074,1127.97C748.701,1127.97 736.22,1115.09 736.22,1099.23C736.22,1083.37 748.701,1070.5 764.074,1070.5Z" fill="white" />
              </g>
            </g>
            <g transform="matrix(0.8,0,0,0.636139,-120.999,-449.52)">
              <path d="M785.532,992.966L785.532,1201.47C785.532,1214.13 777.362,1224.4 767.3,1224.4L758.924,1224.4C748.862,1224.4 740.692,1214.13 740.692,1201.47L740.692,992.966C740.692,980.311 748.862,970.037 758.924,970.037L767.3,970.037C777.362,970.037 785.532,980.311 785.532,992.966Z" fill="white" />
            </g>
            <g transform="matrix(0.597714,0,0,0.488,300.901,-175.616)">
              <path d="M317.836,656.409L384.417,756.959C385.265,758.24 385.436,760.004 384.857,761.493C384.278,762.982 383.051,763.93 381.705,763.93L248.544,763.93C247.197,763.93 245.971,762.982 245.392,761.493C244.813,760.004 244.984,758.24 245.832,756.959L312.412,656.409C313.075,655.408 314.072,654.826 315.124,654.826C316.177,654.826 317.173,655.408 317.836,656.409Z" fill="white" stroke="white" strokeWidth="1.77px" />
            </g>
          </g>
        </g>
        <defs>
          <radialGradient id="_Radial1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(324.242,-291.25,324.346,291.156,690.72,846.026)">
            <stop offset="0" stopColor="rgb(190,35,26)" stopOpacity="1" />
            <stop offset="0.72" stopColor="rgb(220,58,36)" stopOpacity="1" />
            <stop offset="0.87" stopColor="rgb(241,75,43)" stopOpacity="1" />
            <stop offset="1" stopColor="rgb(249,81,46)" stopOpacity="1" />
          </radialGradient>
          <radialGradient id="_Radial2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(324.242,-291.25,324.346,291.156,690.72,846.026)">
            <stop offset="0" stopColor="rgb(190,35,26)" stopOpacity="1" />
            <stop offset="0.72" stopColor="rgb(220,58,36)" stopOpacity="1" />
            <stop offset="0.87" stopColor="rgb(241,75,43)" stopOpacity="1" />
            <stop offset="1" stopColor="rgb(249,81,46)" stopOpacity="1" />
          </radialGradient>
        </defs>
      </svg>
    </Box>;
  if (disabledLink) {
    return logo;
  }
  return <Link component={RouterLink} href="/" sx={{
    display: "contents"
  }}>
      {logo}
    </Link>;
});
export default Logo;