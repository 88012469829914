"use client";

// core
import { enUS as enUSCore, frFR as frFRCore } from "@mui/material/locale";
// data-grid
import { enUS as enUSDataGrid, frFR as frFRDataGrid } from "@mui/x-data-grid";
// date-pickers
import { enUS as enUSDate, frFR as frFRDate } from "@mui/x-date-pickers/locales";
import { enUS as enUSAdapter, fr as frFRAdapter } from "date-fns/locale";
import merge from "lodash/merge";

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: "flagpack:gb-nir",
  },
  {
    label: "French",
    value: "fr",
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: "flagpack:fr",
  },
];

export const defaultLang = allLangs[1]; // French
