"use client";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { useLocales } from "./use-locales";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};
export default function LocalizationProvider({
  children
}: Props) {
  const {
    currentLang
  } = useLocales();
  return <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang.adapterLocale} data-sentry-element="MuiLocalizationProvider" data-sentry-component="LocalizationProvider" data-sentry-source-file="localization-provider.tsx">
      {children}
    </MuiLocalizationProvider>;
}