"use client";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../auth/hooks";
import { useSettingsContext } from "../components/settings/context";
import { allLangs, defaultLang } from "./config-lang";
import type { ILang } from "./i18n";
// import { localStorageGetItem } from "../utils/storage-available";

// ----------------------------------------------------------------------

export function useLocales() {
  const { user } = useAuthContext();
  // const langStorage = localStorageGetItem('i18nextLng');
  const currentLang = allLangs.find((lang) => lang.value === user?.theme_settings.lang) || defaultLang;

  return {
    allLangs,
    currentLang,
  };
}

// ----------------------------------------------------------------------

export function useTranslate() {
  const { t: t18n, i18n, ready } = useTranslation();

  const settings = useSettingsContext();

  const onChangeLang = useCallback(
    (newlang: string) => {
      i18n.changeLanguage(newlang);
      settings.onUpdate("lang", newlang);
      settings.onChangeDirectionByLang(newlang);
    },
    [i18n, settings]
  );

  const t = useCallback(
    (key: ILang | string, format?: "uppercase" | "lowercase" | "capitalize" | undefined) => {
      const value = t18n(key as string);
      if (format === "uppercase") return value.toUpperCase();
      if (format === "lowercase") return value.toLowerCase();
      if (format === "capitalize") return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
      return value;
    },
    [t18n]
  );

  return {
    t,
    i18n,
    ready,
    onChangeLang,
  };
}
