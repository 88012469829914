export const translationEn = {
  coworkers: "coworker",
  app: "app",
  job: "job",
  user: "user",
  list: "list",
  edit: "edit",
  shop: "shop",
  blog: "blog",
  post: "post",
  mail: "mail",
  chat: "chat",
  file: "file",
  tour: "tour",
  cards: "cards",
  blank: "blank",
  order: "order",
  lists: "lists",
  create: "create",
  kanban: "kanban",
  general: "general",
  banking: "banking",
  booking: "booking",
  profile: "profile",
  account: "account",
  product: "product",
  invoice: "invoice",
  details: "details",
  checkout: "checkout",
  calendar: "calendar",
  overview: "overview",
  analytics: "analytics",
  ecommerce: "e-commerce",
  management: "management",
  menu_level: "menu level",
  item_label: "item label",
  description: "description",
  other_cases: "other cases",
  item_caption: "item caption",
  file_manager: "File Manager",
  menu_level_1a: "menu level 1a",
  menu_level_1b: "menu level 1b",
  menu_level_2a: "menu level 2a",
  menu_level_2b: "menu level 2b",
  menu_level_3a: "menu level 3a",
  menu_level_3b: "menu level 4b",
  item_disabled: "item disabled",
  item_by_roles: "item by roles",
  item_external_link: "item external link",
  only_admin_can_see_this_item: "Only admin can see this item",
  upgrade_to_pro: "Upgrade to Pro",
  technical_error: "A technical error has occurred",
  demo: {
    title: "English",
  },
};
