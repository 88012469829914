export const translationFr = {
  coworkers: "collaborateurs",
  app: "application",
  job: "emploi",
  user: "utilisateur",
  list: "liste",
  edit: "modifier",
  shop: "boutique",
  blog: "blog",
  post: "article",
  mail: "courrier",
  chat: "discussion",
  file: "fichier",
  tour: "visite",
  cards: "cartes",
  blank: "vide",
  order: "commande",
  lists: "listes",
  create: "créer",
  kanban: "tableau",
  general: "général",
  banking: "bancaire",
  booking: "réservation",
  profile: "profil",
  account: "compte",
  product: "produit",
  invoice: "facture",
  details: "détails",
  checkout: "paiement",
  calendar: "calendrier",
  overview: "aperçu",
  analytics: "analytics",
  ecommerce: "e-commerce",
  management: "gestion",
  menu_level: "niveau de menu",
  item_label: "étiquette de l'élément",
  description: "description",
  other_cases: "autres cas",
  item_caption: "légende de l'élément",
  file_manager: "Gestionnaire de fichiers",
  menu_level_1a: "niveau de menu 1a",
  menu_level_1b: "niveau de menu 1b",
  menu_level_2a: "niveau de menu 2a",
  menu_level_2b: "niveau de menu 2b",
  menu_level_3a: "niveau de menu 3a",
  menu_level_3b: "niveau de menu 4b",
  item_disabled: "élément désactivé",
  item_by_roles: "élément par rôles",
  item_external_link: "lien externe de l'élément",
  only_admin_can_see_this_item: "Seul l'administrateur peut voir cet élément",
  upgrade_to_pro: "Passer à la version Pro",
  technical_error: "Une erreur technique s'est produite",
  demo: {
    title: "France",
  },
};
