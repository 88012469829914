import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import Logo from "../../components/logo";
import { useOffSetTop } from "../../hooks/use-off-set-top";
import { useTranslate } from "../../locales";
import { addTranslations } from "../../locales/i18n";
import { bgBlur } from "../../theme/css";
import { HEADER } from "../config-layout";
import HeaderShadow from "./header-shadow";
addTranslations({
  en: {
    HeaderSimple: {
      need_help: "Need help?"
    }
  },
  fr: {
    HeaderSimple: {
      need_help: "Besoin d'aide ?"
    }
  }
});

// ----------------------------------------------------------------------

export default function HeaderSimple() {
  const {
    t
  } = useTranslate();
  const theme = useTheme();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  return <AppBar data-sentry-element="AppBar" data-sentry-component="HeaderSimple" data-sentry-source-file="header-simple.tsx">
      <Toolbar sx={{
      justifyContent: "space-between",
      height: {
        xs: HEADER.H_MOBILE,
        md: HEADER.H_DESKTOP
      },
      transition: theme.transitions.create(["height"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
      }),
      ...(offsetTop && {
        ...bgBlur({
          color: theme.palette.background.default
        }),
        height: {
          md: HEADER.H_DESKTOP_OFFSET
        }
      })
    }} data-sentry-element="Toolbar" data-sentry-source-file="header-simple.tsx">
        <Logo data-sentry-element="Logo" data-sentry-source-file="header-simple.tsx" />

        <Stack direction="row" alignItems="center" spacing={1} data-sentry-element="Stack" data-sentry-source-file="header-simple.tsx">
          <Link color="inherit" sx={{
          typography: "subtitle2"
        }} href="mailto:support@uppl.it" rel="noreferrer" target="_blank" data-sentry-element="Link" data-sentry-source-file="header-simple.tsx">
            {t("HeaderSimple.need_help")}
          </Link>
        </Stack>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>;
}