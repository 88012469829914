"use client";

import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { IUserThemeSettings } from "../../../../models/user.model";
import { useAuthContext } from "../../../auth/hooks";
import i18n from "../../../locales/i18n";
import { localStorageGetItem } from "../../../utils/storage-available";
import { SettingsContext } from "./settings-context";

// ----------------------------------------------------------------------

type SettingsProviderProps = {
  children: React.ReactNode;
  defaultSettings?: IUserThemeSettings;
};
const defaultSettingsValue = {
  themeMode: "light" as "light" | "dark",
  themeDirection: "ltr" as "rtl" | "ltr",
  themeContrast: "default" as "default" | "bold",
  themeLayout: "vertical" as "vertical" | "horizontal" | "mini",
  themeColorPresets: "default" as "default" | "cyan" | "purple" | "blue" | "orange" | "red",
  themeStretch: false,
  lang: "fr" as "fr" | "en"
};
export function SettingsProvider({
  children,
  defaultSettings = defaultSettingsValue
}: SettingsProviderProps) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [state, setState] = useState(defaultSettings);
  const {
    user
  } = useAuthContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateState = useCallback((updateValue: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState((prevValue: any) => {
      // setStorage(key, {
      //   ...prevValue,
      //   ...updateValue,
      // });

      return {
        ...prevValue,
        ...updateValue
      };
    });
  }, []);
  const update = useCallback(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (name: string, updateValue: any) => {
    updateState({
      [name]: updateValue
    });
  }, [updateState]);
  const reset = useCallback(() => {
    // removeStorage(key);
    setState(defaultSettings);
  }, [defaultSettings]);
  const isArabic = localStorageGetItem("i18nextLng") === "ar";
  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang("ar");
    }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic]);
  useEffect(() => {
    if (user) {
      updateState(user.theme_settings);
      i18n.changeLanguage(user?.theme_settings.lang);
    }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Direction by lang
  const onChangeDirectionByLang = useCallback((lang: string) => {
    update("themeDirection", lang === "ar" ? "rtl" : "ltr");
  }, [update]);

  // Drawer
  const onToggleDrawer = useCallback(() => {
    setOpenDrawer(prev => !prev);
  }, []);
  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);
  const canReset = !isEqual(state, defaultSettings);
  const memoizedValue = useMemo(() => ({
    ...state,
    onUpdate: update,
    // Direction
    onChangeDirectionByLang,
    // Reset
    canReset,
    onReset: reset,
    // Drawer
    open: openDrawer,
    onToggle: onToggleDrawer,
    onClose: onCloseDrawer
  }), [reset, update, state, canReset, openDrawer, onCloseDrawer, onToggleDrawer, onChangeDirectionByLang]);
  return <SettingsContext.Provider value={memoizedValue} data-sentry-element="unknown" data-sentry-component="SettingsProvider" data-sentry-source-file="settings-provider.tsx">{children}</SettingsContext.Provider>;
}