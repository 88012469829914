import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import React from "react";
import Header from "../common/header-simple";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  sx?: Record<string, string | number>;
};
export default function CompactLayout({
  children,
  sx = {}
}: Props) {
  return <>
      <Header data-sentry-element="Header" data-sentry-source-file="index.tsx" />

      <Container component="main" data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <Stack sx={{
        py: 12,
        m: "auto",
        maxWidth: 400,
        minHeight: "100vh",
        textAlign: "center",
        justifyContent: "center",
        ...sx
      }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          {children}
        </Stack>
      </Container>
    </>;
}