import type { LinkProps } from "next/link";
import Link from "next/link";
import React, { forwardRef } from "react";

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const RouterLink = forwardRef<HTMLAnchorElement, LinkProps>(({
  ...other
}, ref) => <Link ref={ref} {...other} />);
export default RouterLink;